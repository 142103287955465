import React from "react";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";

import LogoSVGURL from "../../svgs/logo-peugeot.svg";

const StyledLogo = styled(ReactSVG)`
  fill: ${props => props.fill || "#DA0621"};
  width: ${props => props.width || "41px"};
  height: ${props => props.height || "34px"};
`;

export default props => {
  return <StyledLogo src={LogoSVGURL} {...props} />;
};
