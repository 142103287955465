import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import get from "lodash/get";
import map from "lodash/map";
import { BubbleLoader } from "react-css-loaders";

import fetchAPI from "../../api";

import Title from "../../components/Title";
import Thumbnail, { ThumbnailWrapper } from "../../components/Thumbnail";
import { Button } from "../../components/Button";

const SpiceWrapper = styled.div`
  padding: 0 30px 30px;

  ${Button} {
    font-weight: normal;
  }
`;

const SpiceGallery = styled.div`
  display: grid;
  grid-gap: 20px;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    &:first-child {
      grid-column: span 2;
    }
  }
`;

const SpiceContent = styled.div`
  margin-top: 20px;
  margin-bottom: 45px;
  line-height: 19px;
`;

const SpiceOrigin = styled.div`
  position: relative;
`;

const SpiceOriginText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  letter-spacing: 1px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
`;

const SpiceLink = styled.div`
  text-align: center;
`;

const SpiceSendRecipe = styled.div`
  text-align: center;
  margin-top: 45px;
  margin-bottom: 40px;
`;

const SpiceThumbnail = styled.div`
  margin-bottom: 70px;
  ${ThumbnailWrapper} {
    text-align: left;
  }
`;

const Cooking = styled.div`
  margin-top: 60px;
`;
const Packshot = styled.div`
  max-width: 120px;
  margin: auto;
`;

export default ({
  match: {
    params: { id }
  }
}) => {
  const [data, setData] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchRecipe(recipeId) {
      return await fetchAPI("singleRecipe", { id: recipeId });
    }
    async function fetchData() {
      setLoading(true);
      const newData = await fetchAPI("singleSpice", { id });
      if (newData) {
        setData(newData);
      }
      setLoading(false);

      const associations = get(newData, "acf.association_recette", []);

      if (associations.length <= 0) return;

      const extraRecipes = await Promise.all(
        map(associations, a => fetchRecipe(a.ID))
      );
      setRecipes(extraRecipes);
    }

    fetchData();
  }, [id]);

  return (
    <SpiceWrapper>
      <Title>{get(data, "title.rendered", "")}</Title>
      {loading ? <BubbleLoader /> : null}
      <SpiceGallery>
        {get(data, "acf.image_top.url") ? (
          <img
            src={get(data, "acf.image_top.url", "//placehold.it/315x295")}
            alt=""
          />
        ) : null}
        {get(data, "acf.image_left.url") ? (
          get(data, "acf.origines") ? (
            <SpiceOrigin>
              <SpiceOriginText>{get(data, "acf.origines", "")}</SpiceOriginText>
              <img
                src={get(data, "acf.image_left.url", "//placehold.it/150x100")}
                alt=""
              />
            </SpiceOrigin>
          ) : null
        ) : null}

        {get(data, "acf.image_right.url") ? (
          <img
            src={get(data, "acf.image_right.url", "//placehold.it/150x100")}
            alt=""
          />
        ) : null}
      </SpiceGallery>

      <SpiceContent
        dangerouslySetInnerHTML={{ __html: get(data, "content.rendered", "") }}
      />

      <Packshot>
        <img src={get(data, "acf.image_packshot.url")} alt="" />
      </Packshot>

      {get(data, "acf.epice_store_link") ? (
        <SpiceLink>
          <Button as="a" href={get(data, "acf.epice_store_link")}>
            J'en veux !
          </Button>
        </SpiceLink>
      ) : null}

      {recipes.length > 0 ? (
        <>
          <Cooking>
            <Title>Cuisiner le {get(data, "title.rendered", "")}</Title>
            <div>
              {map(recipes, recette => (
                <SpiceThumbnail key={recette.id}>
                  <Thumbnail
                    title={get(recette, "title.rendered")}
                    image={get(
                      recette,
                      '_embedded["wp:featuredmedia"]["0"].source_url'
                    )}
                    link={`/recipe/${recette.id}`}
                  />
                </SpiceThumbnail>
              ))}
            </div>
          </Cooking>
          <SpiceSendRecipe>
            <Title>Une idée de recette ?</Title>
            <Button
              as="a"
              href="mailto:support-france@peugeot-saveurs.com?subject=Idée recette"
            >
              Proposez la !
            </Button>
          </SpiceSendRecipe>
        </>
      ) : null}
    </SpiceWrapper>
  );
};
