import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { BubbleLoader } from "react-css-loaders";
import get from "lodash/get";
import map from "lodash/map";

import fetchAPI from "../../api";

import Title from "../../components/Title";
import { Button } from "../../components/Button";
import { subTitle, wysiwyg } from "../../styles/shared";

const Wrapper = styled.div`
  padding: 0 30px 30px;

  ${Title} {
    text-align: left;
  }
`;

const RecipeImage = styled.div`
  margin: 0 -30px;
  img {
    object-fit: cover;
    width: 100%;
    height: 250px;
  }
`;

const RecipeContent = styled.div`
  font-size: 20px;
  line-height: 30px;
`;

const RecipeListing = styled.div`
  ${wysiwyg}
`;

const RecipeInstrument = styled.div`
  ${Title} {
    font-size: 20px;
    text-align: left;
    margin-bottom: 15px;
  }
`;

const RecipeInstrumentTitle = styled.div`
  color: #ff0000;
  font-weight: bold;
  margin-bottom: 35px;
`;
const RecipeInstrumentImage = styled.div`
  margin-bottom: 30px;
`;
const RecipeInstrumentContent = styled.div`
  margin-bottom: 40px;
`;
const RecipeInstrumentButton = styled.div`
  text-align: center;
`;

const RecipeVideo = styled.div`
  ${Title} {
    ${subTitle}
    text-align:left;
  }
  video {
    width: 100%;
  }
`;

export default ({
  match: {
    params: { id }
  }
}) => {
  const [data, setData] = useState([]);
  const [instruments, setInstruments] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const newData = await fetchAPI("singleRecipe", { id });
      if (newData) {
        setData(newData);
      }
      const instrumentsList = get(newData, "acf.instruments_du_gout");

      if (instrumentsList) {
        const inst = await Promise.all(
          instrumentsList.map(i => fetchAPI("singleInstrument", { id: i.ID }))
        );
        if (inst) {
          setInstruments(inst);
        }
      }
    }
    fetchData();
  }, [id]);

  return (
    <Wrapper>
      {get(data, "id") ? (
        <>
          <RecipeImage>
            <img
              src={get(
                data,
                '_embedded["wp:featuredmedia"]["0"].source_url',
                "//placehold.it/375x250"
              )}
              alt=""
            />
          </RecipeImage>
          <Title>{get(data, "title.rendered", "")}</Title>
          <RecipeContent
            dangerouslySetInnerHTML={{ __html: get(data, "content.rendered") }}
          />
          <RecipeListing>
            <div
              dangerouslySetInnerHTML={{
                __html: get(data, "acf.cuisine.ingredients")
              }}
            />
          </RecipeListing>

          {get(data, "acf.cuisine.video") ? (
            <RecipeVideo>
              <Title>Recette en vidéo</Title>
              <video controls autoplay>
                <source
                  src={get(data, "acf.cuisine.video.url")}
                  type={`video/${get(data, "acf.cuisine.video.subtype")}`}
                />
              </video>
            </RecipeVideo>
          ) : null}

          <RecipeListing>
            <div
              dangerouslySetInnerHTML={{
                __html: get(data, "acf.cuisine.preparation")
              }}
            />
          </RecipeListing>

          {instruments && instruments.length > 0 ? (
            <RecipeInstrument>
              <Title>Les instruments du gout</Title>
              {map(instruments, instrument => (
                <div key={instrument.id}>
                  <RecipeInstrumentTitle>
                    {get(instrument, "title.rendered")}
                  </RecipeInstrumentTitle>

                  {get(instrument, "acf.instrument_image.url") ? (
                    <RecipeInstrumentImage>
                      <img
                        src={get(instrument, "acf.instrument_image.url")}
                        alt=""
                      />
                    </RecipeInstrumentImage>
                  ) : null}

                  <RecipeInstrumentContent
                    dangerouslySetInnerHTML={{
                      __html: get(instrument, "content.rendered")
                    }}
                  />
                  {get(instrument, "acf.instrument_store_url") ? (
                    <RecipeInstrumentButton>
                      <Button
                        as="a"
                        href={get(instrument, "acf.instrument_store_url")}
                      >
                        Découvrir
                      </Button>
                    </RecipeInstrumentButton>
                  ) : null}
                </div>
              ))}
            </RecipeInstrument>
          ) : null}
        </>
      ) : (
        <BubbleLoader />
      )}
    </Wrapper>
  );
};
