import styled from "styled-components/macro";

const Title = styled.h1`
  color: ${props => props.color || "inherit"};
  font-weight: bold;
  font-size: ${props => props.size || "25px"};
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
`;

export default Title;
