import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import Title from "../../components/Title";
import Thumbnail from "../../components/Thumbnail";
import { Button } from "../../components/Button";

import play from "../../images/play-placeholder.jpg";

const PlayWrapper = styled.div`
  padding: 0 30px 30px;
`;

const PlayText = styled.div`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 60px;
`;

const PlayButton = styled.div`
  text-align: center;
`;

export default () => {
  return (
    <PlayWrapper>
      <Title>Jouez avec les épices</Title>
      <Thumbnail image={play} />
      <PlayText>
        Cette fonction sera bientôt disponible. En attendant, découvrez nos
        recettes en vous laissant surprendre !
      </PlayText>
      <PlayButton>
        <Button as={Link} to="/selection/">
          Surprenez-moi
        </Button>
      </PlayButton>
    </PlayWrapper>
  );
};
