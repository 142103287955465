import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { BubbleLoader } from "react-css-loaders";
import get from "lodash/get";

import fetchAPI from "../../api";
import Title from "../../components/Title";
import { wysiwyg } from "../../styles/shared";

const LegalWrapper = styled.div`
  padding: 0 30px 30px;
`;

const LegalContent = styled.div`
  ${wysiwyg}
`;

export default () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const newData = await fetchAPI("legals");

      if (newData) {
        setData(newData);
      }
    }
    fetchData();
  }, []);
  return (
    <LegalWrapper>
      <Title>Mentions légales</Title>
      {get(data, "excerpt.rendered", "") ? (
        <LegalContent
          dangerouslySetInnerHTML={{
            __html: get(data, "content.rendered", "")
          }}
        />
      ) : (
        <BubbleLoader />
      )}
    </LegalWrapper>
  );
};
