import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import get from "lodash/get";
import reduce from "lodash/reduce";
import { BubbleLoader } from "react-css-loaders";

import fetchAPI from "../../api";

import Title from "../../components/Title";
import Thumbnail, { ThumbnailWrapper } from "../../components/Thumbnail";
import { Button } from "../../components/Button";

const AlimentWrapper = styled.div`
  padding: 0 30px 30px;

  ${Button} {
    font-weight: normal;
  }
`;

const AlimentContent = styled.div`
  margin-top: 20px;
  margin-bottom: 45px;
  line-height: 19px;
`;

const AlimentThumbnail = styled.div`
  margin-bottom: 70px;
  ${ThumbnailWrapper} {
    text-align: left;
  }
`;

export default ({
  match: {
    params: { id }
  }
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const newData = await fetchAPI("singleAliment", { id });
      if (newData) {
        setData(newData);
      }
      setLoading(false);
    }
    fetchData();
  }, [id]);

  const epicesImgs = reduce(
    get(data, "epices_imgs"),
    (obj, next) => ({ ...obj, [next.id]: next.url }),
    {}
  );

  return (
    <AlimentWrapper>
      {!loading ? (
        <>
          <Title>
            Les meilleures épices avec : {get(data, "title.rendered", "")}
          </Title>

          <AlimentContent
            dangerouslySetInnerHTML={{
              __html: get(data, "content.rendered", "")
            }}
          />

          {(get(data, "acf.epices_associees", []) || []).map(spice => {
            const image = epicesImgs[spice.ID];
            return (
              <AlimentThumbnail key={spice.ID}>
                <Thumbnail
                  title={get(spice, "post_title")}
                  link={`/spice/${spice.ID}`}
                  image={image}
                />
                <div>{get(spice, "post_content")}</div>
              </AlimentThumbnail>
            );
          })}
        </>
      ) : (
        <BubbleLoader />
      )}
    </AlimentWrapper>
  );
};
