import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";

const MenuLinksStyled = styled.nav`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background: #f7f3eb;
  width: 100%;
  transform: translateX(-150%);
  height: calc(100vh - 50px);
  z-index: 5;
  justify-content: center;
  align-items: center;
  ${props =>
    props.expanded &&
    css`
      transform: translateX(0);
    `};
`;

const StyledLink = styled(Link)`
  margin: 20px;
  font-size: 25px;
  font-weight: bold;
  text-decoration: none;
  color: #000;
`;

const MenuLinks = ({ expanded }) => {
  return (
    <MenuLinksStyled expanded={expanded}>
      <StyledLink to="/">Accueil</StyledLink>
      <StyledLink to="/by/spice/">Par épices</StyledLink>
      <StyledLink to="/by/aliment/">Par plat</StyledLink>
      <StyledLink to="/play/">Jouez !</StyledLink>
      <StyledLink to="/share/">Partagez l’app</StyledLink>
      <StyledLink to="/legals/">Mention Légales</StyledLink>
    </MenuLinksStyled>
  );
};

export default MenuLinks;
