import styled from "styled-components/macro";

export const Button = styled.button`
  color: black;
  font-size: 25px;
  line-height: 1;
  margin: 0;
  padding: 8px 30px 8px 30px;
  min-width: 230px;
  border: 3px solid black;
  border-radius: 22px;
  background: none;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  font-family: "Nexa";
  font-weight: lighter;
`;

// A new component based on Button, but with some override styles
export const NegativeButton = styled(Button)`
  color: white;
  border-color: white;
`;

export default Button;
