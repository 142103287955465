import axios from "axios";

const BASE_URL = "https://peugeotsaveurs.komk.fr/wp-json/wp/v2";

export const routes = {
  spice: {
    method: "get",
    url: `${BASE_URL}/epices?_embed&per_page=100`
  },
  singleSpice: ({ id }) => ({
    method: "get",
    url: `${BASE_URL}/epices/${id}?_embed`
  }),
  aliment: {
    method: "get",
    url: `${BASE_URL}/aliments?_embed&per_page=100`
  },
  singleAliment: ({ id }) => ({
    method: "get",
    url: `${BASE_URL}/aliments/${id}?_embed`
  }),
  recipes: {
    method: "get",
    url: `${BASE_URL}/recettes?_embed&per_page=100`
  },
  singleRecipe: ({ id }) => ({
    method: "get",
    url: `${BASE_URL}/recettes/${id}?_embed`
  }),
  singleInstrument: ({ id }) => ({
    method: "get",
    url: `${BASE_URL}/instruments/${id}?_embed`
  }),
  legals: {
    method: "get",
    url: `${BASE_URL}/pages/141`
  }
};

export default async (route, urlParams, params) => {
  try {
    if (!route) {
      throw new Error("missing route parameter");
    }
    if (!routes[route]) {
      throw new Error("route doesn't exists");
    }

    let routeConfig = routes[route];

    if (typeof routeConfig === "function") {
      routeConfig = routeConfig(urlParams);
    }

    const { data } = await axios({
      ...routeConfig,
      ...params
    });

    return data;
  } catch (error) {
    console.error(error);
  }
};
