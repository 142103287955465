import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { BubbleLoader } from "react-css-loaders";
import { Link } from "react-router-dom";

import sample from "lodash/sample";
import get from "lodash/get";

import fetchAPI from "../../api";

import Title from "../../components/Title";
import { Button } from "../../components/Button";

const SelectionContent = styled.div`
  padding: 0 30px 30px;
`;

const SelectionTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const SelectionImg = styled.div`
  margin-bottom: 20px;
  img {
    object-fit: cover;
    height: 405px;
    width: 100%;
  }
`;
const SelectionExcerpt = styled.div``;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

const SelectionButton = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
`;

export default ({ location }) => {
  const [data, setData] = useState([]);
  const [pick, setPick] = useState(undefined);
  const [viewed, setViewed] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const newData = await fetchAPI("recipes");
      if (newData) {
        setData(newData);
      }
    }
    fetchData();
  }, [location]);

  useEffect(() => {
    setRandomPick();
  }, [data]);

  useEffect(() => {
    if (viewed.length > 0 && viewed.length === data.length) {
      setViewed([]);
    }
  }, [viewed]);

  const setRandomPick = () => {
    const pick = sample(data.filter(item => viewed.indexOf(item.id) === -1));
    if (typeof pick !== "undefined") {
      setViewed([...viewed, pick.id]);
      setPick(pick);
    }
  };

  return (
    <div>
      <Title>Notre sélection</Title>
      {get(pick, "id") ? (
        <>
          <SelectionContent>
            <StyledLink to={`/recipe/${get(pick, "id")}`}>
              <SelectionImg>
                <img
                  src={get(
                    pick,
                    '_embedded["wp:featuredmedia"]["0"].source_url',
                    "//placehold.it/315x405"
                  )}
                  alt=""
                />
              </SelectionImg>
              <SelectionTitle>{get(pick, "title.rendered")}</SelectionTitle>
              <SelectionExcerpt
                dangerouslySetInnerHTML={{
                  __html: get(pick, "excerpt.rendered", "")
                }}
              />
            </StyledLink>
          </SelectionContent>
          <SelectionButton>
            <Button
              onClick={() => {
                setRandomPick();
              }}
            >
              Encore !
            </Button>
          </SelectionButton>
        </>
      ) : (
        <BubbleLoader />
      )}
    </div>
  );
};
