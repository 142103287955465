import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

import Intro from "../../components/Intro";
import Title from "../../components/Title";
import { NegativeButton as Button } from "../../components/Button";

import img from "../../images/home-bg-resize.jpg";

const HomePage = styled.div`
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Title} {
    max-width: 260px;
    margin: 0 auto 50px;
    text-align: center;
  }
  ${Button} {
    margin: 20px auto;
  }
`;

export default () => {
  return (
    <>
      <HomePage>
        <Title>Comment souhaitez vous voyager ?</Title>
        <Button as={Link} to="/by/spice/">
          Par épice
        </Button>
        <Button as={Link} to="/by/aliment/">
          Par plat
        </Button>
        <Button as={Link} to="/selection/">
          Surprenez-moi
        </Button>
        <Button as={Link} to="/play/">
          Jouez !
        </Button>
      </HomePage>

      <Intro />
    </>
  );
};
