import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import Logo from "../../components/Logo";
import Title from "../../components/Title";
import P from "../../components/P";
import { NegativeButton as Button } from "../../components/Button";

import img from "../../images/splash.jpg";

const WrapperIntro = styled.section`
  background-color: papayawhip;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  ${Title} {
    margin-bottom: 30px;
  }

  ${P} {
    max-width: 320px;
    text-align: center;
    margin-bottom: 95px;
  }
`;

const Intro = () => {
  const initialState = window.sessionStorage.getItem("intro") || "true";
  const [active, setActive] = useState(initialState);
  useEffect(() => {
    if (active === "false") {
      window.sessionStorage.setItem("intro", "false");
    }
  }, [active]);

  return active === "true" ? (
    <WrapperIntro>
      <Logo fill="white" width="95px" height="90px" />

      <Title color="white">Bienvenue</Title>
      <P color="white">
        Découvrez nos épices à travers nos recettes gourmandes et voyagez...
      </P>
      <Button onClick={() => setActive("false")}>En avant</Button>
    </WrapperIntro>
  ) : null;
};

export default Intro;
