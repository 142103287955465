import React from "react";
import styled from "styled-components/macro";

import Title from "../../components/Title";
import Thumbnail from "../../components/Thumbnail";
import { Button } from "../../components/Button";

import play from "../../images/share-placeholder.jpg";

const ShareWrapper = styled.div`
  padding: 0 30px 30px;
`;

const ShareText = styled.div`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 60px;
`;

const ShareButton = styled.div`
  text-align: center;
`;

export default () => {
  return (
    <ShareWrapper>
      <Title>Partagez l'app</Title>
      <Thumbnail image={play} />
      <ShareText>
        Faites profitez de cette application à vos amis ! Partagez vos recettes
        et vos épices préférées sur Facebook !
      </ShareText>
      <ShareButton>
        <Button
          as="a"
          href="http://www.facebook.com/sharer.php?u=https%3A%2F%2Ffr.peugeot-saveurs.com%2Finspiration%2Fapplication"
        >
          C’est parti !
        </Button>
      </ShareButton>
    </ShareWrapper>
  );
};
