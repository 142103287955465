import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

export const ThumbnailWrapper = styled(Link)`
  display: block;
  margin-bottom: 20px;
  text-align: center;
  text-decoration: none;
`;

const ThumbnailImage = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;
const ThumbnailTitle = styled.div`
  margin-top: 18px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
`;

const Thumbnail = ({ image, link, title }) => {
  return (
    <ThumbnailWrapper to={link}>
      <ThumbnailImage>
        <img src={image ? image : "//placehold.it/300x300"} alt="" />
      </ThumbnailImage>
      <ThumbnailTitle>{title}</ThumbnailTitle>
    </ThumbnailWrapper>
  );
};

export default Thumbnail;
