import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import Menu from "./components/Menu";
import ScrollToTop from "./components/ScrollToTop";

import Home from "./containers/Home";
import GridBy from "./containers/GridBy";
import Selection from "./containers/Selection";
import Spice from "./containers/Spice";
import Aliment from "./containers/Aliment";
import Recipe from "./containers/Recipe";
import Play from "./containers/Play";
import Legals from "./containers/Legals";
import Share from "./containers/Share";

import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import Nexa from "./fonts/Nexa.woff2";
import NexaLight from "./fonts/NexaLight.woff2";
import NexaBold from "./fonts/NexaBold.woff2";

const GlobalStyle = createGlobalStyle`
  ${reset}
    @font-face {
      font-family: 'Nexa';
      src: url('${Nexa}') format('woff2');
      font-weight: normal;
      font-style: normal;
  }
  @font-face {
      font-family: 'Nexa';
      src: url('${NexaLight}') format('woff2');
      font-weight: lighter;
      font-style: normal;
  }
  @font-face {
      font-family: 'Nexa';
      src: url('${NexaBold}') format('woff2');
      font-weight: bold;
      font-style: normal;
  }

  * {
    box-sizing: border-box;
    &:before, &:after {
      box-sizing: border-box;
    }
  }
  html {
    overflow: hidden;
    height: 100vh;
  }
  body {
    font-family: 'Nexa';
    background-color: #F7F3EB;
    color: #000;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    height: 100vh;
  }  
  img {
    max-width: 100%;
    height: auto;
  }
  p {
    line-height: 24px;
  }
  #root {
    height: 100vh;
    overflow-y: auto;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }
`;

class App extends Component {
  render() {
    return (
      <>
        <GlobalStyle />
        <Router>
          <ScrollToTop>
            <Menu />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/by/:type/" exact component={GridBy} />
              <Route path="/selection/" exact component={Selection} />
              <Route path="/spice/:id/" exact component={Spice} />
              <Route path="/aliment/:id/" exact component={Aliment} />
              <Route path="/recipe/:id/" exact component={Recipe} />
              <Route path="/play/" exact component={Play} />
              <Route path="/legals/" exact component={Legals} />
              <Route path="/share/" exact component={Share} />
            </Switch>
          </ScrollToTop>
        </Router>
      </>
    );
  }
}

export default App;
