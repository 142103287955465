import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import get from "lodash/get";
import { BubbleLoader } from "react-css-loaders";

import fetchAPI from "../../api";
import translate from "../../text";

import Title from "../../components/Title";
import Thumbnail from "../../components/Thumbnail";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 0 30px 50px;
`;

export default ({
  match: {
    params: { type }
  }
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const inStorage = window.sessionStorage.getItem(`${type}`);
    const initialState = inStorage ? JSON.parse(inStorage) : [];

    async function fetchData() {
      if (initialState.length === 0) {
        setLoading(true);
        const newData = await fetchAPI(type);
        if (newData) {
          setData(newData);
          window.sessionStorage.setItem(`${type}`, JSON.stringify(newData));
        }
      }

      setLoading(false);
    }
    setData(initialState);
    fetchData();
  }, [type]);

  return (
    <div>
      <Title>Par {translate(type)}</Title>

      {loading ? <BubbleLoader /> : null}

      <GridWrapper>
        {data.map(el => {
          const image =
            get(el, "acf.image_right.url") ||
            get(el, '_embedded["wp:featuredmedia"]["0"].source_url');
          return (
            <Thumbnail
              key={el.id}
              title={get(el, "title.rendered")}
              image={image}
              link={`/${type}/${el.id}`}
            />
          );
        })}
      </GridWrapper>
    </div>
  );
};
