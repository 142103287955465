import { css } from "styled-components/macro";

export const subTitle = css`
  font-size: 20px;
  line-height: 24px;
  margin-top: 45px;
  margin-bottom: 40px;
  font-weight: bold;
`;

export const wysiwyg = css`
  h1,
  h2,
  h3 {
    ${subTitle}
  }
  strong {
    font-weight: bold;
  }
  p {
    margin-top: 30px;
  }
  ul {
    margin-top: 15px;
    list-style-position: outside;
    padding-left: 30px;
    li {
      margin-bottom: 10px;
      line-height: 24px;
      &:before {
        content: "";
        height: 3px;
        width: 20px;
        background: #ff0000;
        display: inline-block;
        margin-right: 10px;
        margin-left: -30px;
        margin-top: -3px;
        vertical-align: middle;
      }
    }
  }
`;
