import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import ReactSVG from "react-svg";

import Logo from "../../components/Logo";
import MenuLinks from "../../components/MenuLinks";
import hamburger from "../../svgs/hamburger.svg";
import close from "../../svgs/close.svg";
import arrow from "../../svgs/arrow.svg";

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 70px;
  background: #fff;
  padding: 0 25px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const MenuBack = styled.button`
  border: 0;
  position: relative;
  z-index: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  ${props =>
    props.expanded &&
    css`
      z-index: -1;
    `};
`;

const MenuToggler = styled.button`
  position: relative;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  width: 30px;
  height: 30px;
`;

const Menu = ({ history, location }) => {
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    setMenu(false);
  }, [location]);

  return (
    <MenuWrapper>
      <MenuBack expanded={menu} onClick={() => history.goBack()}>
        {location.pathname !== "/" ? <ReactSVG src={arrow} /> : null}
      </MenuBack>

      <Link to="/">
        <Logo />
      </Link>

      <MenuToggler onClick={() => setMenu(!menu)}>
        {menu ? <ReactSVG src={close} /> : <ReactSVG src={hamburger} />}
      </MenuToggler>
      <MenuLinks expanded={menu} />
    </MenuWrapper>
  );
};

export default withRouter(Menu);
